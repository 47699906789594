import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import "./App.css";
import reportWebVitals from "./reportWebVitals";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import { SharedDataSet } from "./CommonPage/SharedDataSet";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    {/* <React.StrictMode> */}
    <ToastContainer />
    <SharedDataSet>
      <App />
    </SharedDataSet>
    {/* </React.StrictMode> */}
  </>
);

reportWebVitals();