import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Login from "./Authentication/Login";
import AddUser from "./Pages/User/AddUser";
import MessageRecords from "./Pages/message/MessageRecord";
import Upload from "./Pages/uploaded/Upload";
import ProtectedRoute from "./ProtectedRoute";
import UserLogin from "./Authentication/UserLogin";
import Mfa from "./Authentication/Mfa";
import User from "./Pages/UserPage/User";
import UserProtect from "./UserProtect";

function App() {
  let type = sessionStorage.getItem("type");

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/user" element={<UserLogin />} />
          <Route path="/mfa" element={<Mfa />} />
          <Route
            path="/user_home"
            element={
              <UserProtect>
                <User />
              </UserProtect>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <AddUser />
              </ProtectedRoute>
            }
          />
          <Route
            path="/uploaded_file"
            element={
              <ProtectedRoute>
                <Upload />
              </ProtectedRoute>
            }
          />
          <Route
            path="/message_records"
            element={
              <ProtectedRoute>
                <MessageRecords />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
