import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
let type = localStorage.getItem("fileType");
let auth = localStorage.getItem("auth");

const User = () => {
  const [dataStatus, setDataStatus] = useState();
  const [ip, setIp] = useState("");
  const [downloadMessage, setDownloadMessage] = useState("");

  useEffect(() => {
    const fetchIp = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        setIp(response.data.ip);
      } catch (error) {
        console.error("Error fetching the IP address:", error);
      }
    };

    fetchIp();
  }, []);

  useEffect(() => {
    document.title = "Download";
  }, []);

  const fetchData = async () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}message/single?type=${type}&auth=${auth}`
      )
      .then(function (res) {
        console.log("admi", res.data?.file);
        setDataStatus(res.data?.file);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const download = async (e) => {
    e.preventDefault();
    try {
      const spinner = `<span className="spinner-border spinner-border-sm"></span> Loading.`;
      const btns = document.getElementById("submitButton");
      btns.innerHTML = spinner;
      btns.disabled = true;
      const res = await axios.put(
        `${process.env.REACT_APP_BASE_URL}message/download`,
        {
          ip: ip,
          auth: auth,
          id: dataStatus?.id,
        }
      );
      if (res.status == 200) {
        toast.success(res.data.message);
        const a = document.createElement("a");
        a.download = res.data.fileName;
        a.href = res.data.url;
        a.click();
        const btns = document.getElementById("submitButton");
        btns.innerHTML = "Downloaded";
        btns.disabled = true;
        setDownloadMessage("Your file has been downloaded");
      }
    } catch (err) {
      console.error("Error:", err);
      toast.error(err.response.data.message, { autoClose: 1000 });
    }
  };

  return (
    <>
      <div id="layout" className="data-bs-theme">
        <div id="wrapper">
          <nav className="navbar navbar-fixed-top">
            <div className="container-fluid">
              <div className="navbar-brand pt-1">
                <span className="">
                  <b>Download File</b>
                </span>
              </div>
            </div>
          </nav>
          <div className="mt-5">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-md-6 mx-auto">
                  <div className="card">
                    <div className="card-header custom-header">
                      <span className="tw-text-[22px]">
                        <b>Download File information</b>
                      </span>
                    </div>
                    <div className="card-body">
                      <p className="tw-border-[1px] text-danger tw-border-red-600 px-2">
                        <b className="text-danger">Note: </b>The download link
                        is one-time use only. After the first download, it
                        expires. Save and verify the file immediately. If you
                        encounter issues or need the file again, request a new
                        link. If you are not the intended recipient, do not
                        download the attachment to avoid legal consequences.
                      </p>
                      <p className="px-2 mt-2">
                        <b>File Description: </b>
                        {dataStatus?.Description || "None"}
                      </p>
                      {dataStatus?.status === false ? (
                        <div className="row">
                          <button
                            type="submit"
                            id="submitButton"
                            onClick={download}
                            className="btn btn-primary mx-auto px-3 mt-2 py-2 col-sm-6 col-md-3"
                          >
                            Download Now
                          </button>
                          {downloadMessage && (
                            <p className="text-danger text-center mt-3">
                              {downloadMessage}
                            </p>
                          )}
                        </div>
                      ) : (
                        <div className="row">
                          <h3 className="text-danger tw-text-xl mt-3 text-center">
                            Link Expired.
                          </h3>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default User;
