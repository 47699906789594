import React, { useEffect, useState } from "react";
import $ from "jquery";
import { useSharedContext } from "./SharedDataSet";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function TopBar(props) {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const mobileMenuBar = () => {
    $("body").toggleClass("offcanvas-active");
  };
  const { emailValue } = useSharedContext();
  const { notificationValue } = useSharedContext();
  const [emailBtn, setEmailBtn] = useState(emailValue);
  const [notificationBtn, setNotificationBtn] = useState(notificationValue);

  useEffect(() => {
    setEmailBtn(emailValue);
    setNotificationBtn(notificationValue);
  }, [emailValue, notificationValue]);

  const handleLogout = async () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("email");
    localStorage.removeItem("subMenus");
    localStorage.removeItem("menuType");
    localStorage.removeItem("menuItems");
    sessionStorage.removeItem("type");
    window.location.href = "/";
  };

  const showDot = data && Object.values(data).some((value) => value > 0);

  return (
    <nav className="navbar navbar-fixed-top">
      <div className="container-fluid">
        <div className="navbar-btn">
          <button
            type="button"
            className="btn-toggle-offcanvas"
            onClick={mobileMenuBar}
          >
            <i className="fa fa-bars"></i>
          </button>
        </div>
        <div className="navbar-brand">
          <span className="">
            <b>Secure File Sharing System</b>
          </span>
        </div>

        <div className="d-flex flex-grow-1 align-items-center">
          <div className="flex-grow-1">
            <ul className="nav navbar-nav flex-row justify-content-end align-items-center">
              {emailBtn == true ? (
                <li>
                  <a href="#" className="icon-menu">
                    <i className="fa fa-envelope"></i>
                    <span className="notification-dot"></span>
                  </a>
                </li>
              ) : (
                ""
              )}
              {notificationBtn == true ? (
                <li className="dropdown">
                  <a
                    className="dropdown-toggle icon-menu"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fa fa-bell"></i>

                    <span className={showDot ? "notification-dot" : ""}></span>
                  </a>
                  <div className="dropdown-menu dropdown-menu-end p-0 shadow notification">
                    <ul className="list-unstyled feeds_widget">
                      <li className="d-flex">
                        <div className="feeds-left">
                          <i className="fa fa-user"></i>
                        </div>
                        <div className="feeds-body flex-grow-1">
                          <Link to="/new_user_request">
                            <h6 className="mb-1">
                              <span className="tw-text-green-500">
                                {data?.newRequestData || "0"} New User Request
                              </span>
                            </h6>
                          </Link>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="feeds-left">
                          <i className="fa fa-user"></i>
                        </div>
                        <div className="feeds-body flex-grow-1">
                          <Link to="/new_pending_request">
                            <h6 className="mb-1 text-warning">
                              {data?.pendingData || "0"} Pending Request
                            </h6>
                          </Link>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="feeds-left">
                          <i className="fa fa-user"></i>
                        </div>
                        <div className="feeds-body flex-grow-1">
                          <Link to="/new_provider_request">
                            <h6 className="mb-1">
                              <span className="tw-text-yellow-300">
                                {data?.pendingProvider || "0"} Pending Provider
                                Request
                              </span>
                            </h6>
                          </Link>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="feeds-left">
                          <i className="fa fa-user"></i>
                        </div>
                        <div className="feeds-body flex-grow-1">
                          <Link to="/new_users_request">
                            <h6 className="mb-1">
                              <span className="tw-text-yellow-300">
                                {data?.pendingUser || "0"} Pending User Request
                              </span>
                            </h6>
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              ) : (
                ""
              )}

              {/* more link  */}
              <li className="dropdown ">
                <a
                  className="dropdown-toggle icon-menu d-none"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fa fa-sliders"></i>
                </a>
                <ul className="dropdown-menu dropdown-menu-end p-2 shadow">
                  <li>
                    <a className="dropdown-item rounded-pill" href="#">
                      <i className="me-2 fa fa-pencil-square-o"></i>
                      <span>Basic</span>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item rounded-pill" href="#">
                      <i className="me-2 fa fa-sliders fa-rotate-90"></i>
                      <span>Preferences</span>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item rounded-pill" href="#">
                      <i className="me-2 fa fa-lock"></i> <span>Privacy</span>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item rounded-pill" href="#">
                      <i className="me-2 fa fa-bell"></i>
                      <span>Notifications</span>
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <a className="dropdown-item rounded-pill" href="#">
                      <i className="me-2 fa fa-credit-card"></i>
                      <span>Payments</span>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item rounded-pill" href="#">
                      <i className="me-2 fa fa-print"></i> <span>Invoices</span>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item rounded-pill" href="#">
                      <i className="me-2 fa fa-refresh"></i>
                      <span>Renewals</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a
                  onClick={handleLogout}
                  className="icon-menu tw-cursor-pointer logout-main"
                  title="Logout"
                >
                  <i className="fa fa-sign-out"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default TopBar;
