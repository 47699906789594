import React, { useState, useRef } from "react";
import Preloader from "../../CommonPage/Preloader";
import TopBar from "../../CommonPage/TopBar";
import SideBar from "../../CommonPage/SideBar";
import ToggleNavBar from "../../CommonPage/ToggleNavBar";
import "select2/dist/css/select2.min.css";
import axios from "axios";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.snow.css";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import Upload from "../uploaded/Upload";

var toolbarOptions = [
  ["bold", "italic", "underline"],
  [{ list: "ordered" }],
  [{ align: [] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ font: [] }],
  ["table"], // Corrected to use "table" as a string
  [{ script: "sub" }, { script: "super" }],
  [{ color: [] }, { background: [] }],
];

function AddUser() {
  const token = sessionStorage.getItem("token");
  const initialUploadState = {
    file: null,
    email: "",
    type: "",
    Description: "",
  };
  const rowInitialState = {
    email: "",
    Description: "",
  };
  const validateEmailId = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };
  const [upLoad, setUpLoad] = useState(initialUploadState);
  const [row, setRow] = useState(rowInitialState);
  const [preloaderOn, setPreloaderOn] = useState(false);
  const [editorContent, setEditorContent] = useState(""); // Add state for editor content

  const handleChangePost = (event) => {
    const { name, value, files } = event.target;

    if (name === "file" && files.length > 0) {
      setUpLoad({
        ...upLoad,
        [name]: files[0],
      });
    } else {
      setUpLoad({
        ...upLoad,
        [name]: value,
      });
    }
  };
  const fileInputRef = useRef(null);

  const handleChangeMessage = (e) => {
    const { name, value } = e.target;
    setRow({ ...row, [name]: value });
  };

  const handleUpdate = (e) => {
    setPreloaderOn(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", upLoad.file);
    formData.append("email", upLoad.email);
    formData.append("type", upLoad.type);
    formData.append("Description", upLoad.Description);

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}message/add?type=file`,
        formData,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        setUpLoad(initialUploadState);
        if (fileInputRef.current) {
          fileInputRef.current.value = ""; // Resetting the file input
        }
        toast.success(res.data.message, { autoClose: 1000 });
        setPreloaderOn(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, { autoClose: 1000 });
        setPreloaderOn(false);
      });
  };

  const handleTemplateDataMainTitle = (quilData) => {
    setRow({ ...row, Description: quilData });
    setEditorContent(quilData); // Update the editor content state
  };

  const handleUpdateMessage = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_BASE_URL}message/add?type=message`, row, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setRow((row.email = ""));
        setEditorContent(""); // Clear the editor content
        toast.success(res.data.message, { autoClose: 1000 });
      })
      .catch((error) => {
        toast.error(error.response.data.message, { autoClose: 1000 });
      });
  };

  const validateEmail = (e) => {
    const { name, value } = e.target;
    console.log(value);
    const check = validateEmailId(value);
    if (check) {
      setUpLoad({
        ...upLoad,
        [name]: value,
      });
    } else {
      toast.warning("Invalid Email Address", { autoClose: 1000 });
    }
  };

  return (
    <div id="layout" className="theme-blue">
      {preloaderOn == true ? (
        <Preloader text="Please wait while file is uploading..." />
      ) : (
        ""
      )}
      <div id="wrapper">
        <TopBar />
        <SideBar />
        <div id="main-content">
          <div className="container-fluid">
            <div className="block-header py-lg-4 py-3">
              <div className="row g-3">
                <div className="col-md-6 col-sm-12">
                  <h2 className="m-0 fs-5">
                    <ToggleNavBar />
                    Send File/Message
                  </h2>
                  <ul className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">sLEAP</Link>
                    </li>
                    <li className="breadcrumb-item active">
                      Send File/Message
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="">
              <div className="row">
                <div className="col-md-6 mb-3 mb-md-0">
                  <form
                    className="form-box"
                    id="yourFormId"
                    onSubmit={handleUpdate}
                  >
                    <div className="card">
                      <div className="card-header d-flex align-items-center justify-content-between">
                        <h6 className="card-title">Upload File</h6>
                      </div>
                      <div className="card-body">
                        <div className="row g-3 ">
                          <div className="col-12">
                            <label className="form-label">Upload File</label>
                            <input
                              type="file"
                              className="form-control"
                              name="file"
                              ref={fileInputRef}
                              onChange={handleChangePost}
                              required
                            />
                          </div>
                          <div className="col-12">
                            <label className="form-label">E-mail</label>
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              value={upLoad.email}
                              onChange={handleChangePost}
                              required
                              onBlur={validateEmail}
                            />
                          </div>
                          <div className="col-12">
                            <label className="form-label">
                              Receiver Vendor
                            </label>
                            <select
                              className="form-select"
                              name="type"
                              value={upLoad.type}
                              onChange={handleChangePost}
                            >
                              <option value="">Select</option>
                              <option value="JB Hunt">JB Hunt</option>
                              <option value="Schneider">Schneider</option>
                              <option value="Swift">Swift</option>
                              <option value="Care Orchestator (Phillips)">
                                Care Orchestator (Phillips)
                              </option>
                            </select>
                          </div>
                          <div className="col-12">
                            <label className="form-label">Description</label>
                            <textarea
                              row={10}
                              type="text"
                              className="form-control"
                              name="Description"
                              value={upLoad.Description}
                              onChange={handleChangePost}
                            />
                          </div>
                        </div>
                        <div className="col-12 mt-4 mb-2 px-2 text-end">
                          <button
                            type="submit"
                            className="btn submit-btn d-inline ms-3"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                <div className="col-md-6">
                  <form
                    className="form-box"
                    id="yourFormId"
                    style={{ height: "100%" }}
                    onSubmit={handleUpdateMessage}
                  >
                    <div className="card" style={{ height: "100%" }}>
                      <div className="card-header d-flex align-items-center justify-content-between">
                        <h6 className="card-title">Message</h6>
                      </div>
                      <div className="card-body">
                        <div className="row g-3 ">
                          <div className="col-12 ">
                            <label className="form-label">E-mail</label>
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              value={row.email}
                              onChange={handleChangeMessage}
                              required
                            />
                          </div>
                          <div className="col-12">
                            <label className="form-label">Message</label>
                            <ReactQuill
                              theme="snow"
                              name="Description"
                              value={editorContent} // Bind state to the editor
                              onChange={(content, delta, source, editor) =>
                                handleTemplateDataMainTitle(content)
                              }
                              modules={{ toolbar: toolbarOptions }}
                            ></ReactQuill>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 tw-mt-[22px] mb-2 px-2 text-end">
                        <button
                          type="submit"
                          className="btn submit-btn d-inline ms-3 "
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddUser;
