import React, { useEffect, useState } from "react";
import Preloader from "../../CommonPage/Preloader";
import TopBar from "../../CommonPage/TopBar";
import SideBar from "../../CommonPage/SideBar";
import ToggleNavBar from "../../CommonPage/ToggleNavBar";
import "select2/dist/css/select2.min.css";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Table } from "../../CommonPage/TableContent";

function MessageRecords() {
  const token = sessionStorage.getItem("token");
  const convertTimestampToDateFormat = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };
  const [data, setData] = useState([]);
  const [preloaderOn, setPreloaderOn] = useState(false);

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: "sno",
      },
      {
        Header: "Email",
        accessor: "name",
      },
      {
        Header: "Message",
        accessor: "description",
      },
      {
        Header: "Sent On",
        accessor: "created",
      },
      {
        Header: "Status",
        accessor: "status",
      },
    ],
    []
  );

  const fetchData = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}message/get`, {
        headers: {
          Authorization: token,
        },
      })
      .then(function (res) {
        console.log("admi", res.data.message);
        const formattedData = res.data.message.map((item, index) => ({
          sno: index + 1,
          name: item?.email,
          description: item?.Description ? (
            <span
              className="pageTitle"
              dangerouslySetInnerHTML={{
                __html: item?.Description,
              }}
            ></span>
          ) : (
            ""
          ),
          file: item?.fileName,
          status: item?.Active ? (
            <span className="badge bg-success">Seen</span>
          ) : (
            <span className="badge bg-danger">Not Seen</span>
          ),
          created: convertTimestampToDateFormat(item?.createdAt),
        }));

        setData(formattedData);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div id="layout" className="theme-blue">
      {preloaderOn == true ? <Preloader text="Resending..." /> : ""}
      <div id="wrapper">
        <TopBar />
        <SideBar />
        <div id="main-content">
          <div className="container-fluid">
            <div className="block-header py-lg-4 py-3">
              <div className="row g-3">
                <div className="col-md-6 col-sm-12">
                  <h2 className="m-0 fs-5">
                    <ToggleNavBar />
                    Message Records
                  </h2>
                  <ul className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to="#">sLEAP</Link>
                    </li>
                    <li className="breadcrumb-item active">Message Records</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row clearfix row-deck">
              <div className="col-lg-12 col-md-12">
                <div className="card">
                  <div className="card-body">
                    <Table columns={columns} data={data} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MessageRecords;
