import { param } from "jquery";
import React from "react";
import { useLocation, useParams } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const user = sessionStorage.getItem("token");
  const { dataId } = useParams();
  localStorage.setItem("dataMainID", dataId);

  if (!user) {
    setTimeout(() => {
      window.location.href = "/";
    }, 0);

    return null;
  }

  return children;
};

export default ProtectedRoute;
