import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Jbhunt from "../jbhuntproject.png";

function SideBar() {
  return (
    <div id="left-sidebar" className="sidebar">
      <div className="user-account p-3 ">
        <div className=" d-flex border-bottom justify-content-center align-items-center">
          <div
            className="profile-image m-3 border-2 "
            style={{ height: "80px", width: "80px" }}
          >
            <img
              src={"/nav.jpg"}
              className="rounded-circle tw-text-xs"
              alt=""
            />
          </div>
          <br />
        </div>
      </div>

      <div className="tab-content px-0">
        <div
          className={"tab-pane fade show active border-bottom"}
          id="hr_menu"
          role="tabpanel"
        >
          <nav className="sidebar-nav">
            <ul className="metismenu list-unstyled" id="menu">
              <li className="dashboard">
                <NavLink
                  className="menu-item-single"
                  to="/dashboard"
                >
                  <i className="fa fa-tachometer"></i>
                  <span>Dashboard</span>
                </NavLink>
              </li>

              <li className="uploaded">
                <NavLink
                  className="menu-item-single"
                  to="/uploaded_file"
                >
                  <i className="fa fa-columns"></i>
                  <span>Uploaded Files</span>
                </NavLink>
              </li>

              <li className="message">
                <NavLink
                  className="menu-item-single"
                  to="/message_records"
                >
                  <i className="fa fa-columns"></i>
                  <span>Message Records</span>
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="user-account p-3 mt-2">
        <div className=" border-bottom ">
          <img
            src={Jbhunt}
            className="avatar lg rounded me-3"
            alt="User Profile"
            style={{ height: "150px", width: "160px" }}
          />
        </div>
        <div className="my-2">
          <span className="d-flex justify-content-center align-content-center">
            Autorised User:
          </span>
          <p className="text-center"> Secured Credentials</p>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
