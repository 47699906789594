import React from "react";

const UserProtect = ({ children }) => {
  const auth = localStorage.getItem("auth");

  if (!auth) {
    setTimeout(() => {
      window.location.href = "/user";
    }, 0);

    return null;
  }

  return children;
};

export default UserProtect;
